import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class PersonalFolderPage extends Component {
  constructor(props) {
    super(props);
    this.youtubeContainer = React.createRef();
  }
  componentDidMount() {
    this.setVideoHeight();
    window.addEventListener("resize", this.setVideoHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setVideoHeight);
  }

  setVideoHeight = () => {
    this.youtubeContainer.current.style.height = `${
      (this.youtubeContainer.current.clientWidth * 9) / 16
    }px`;
  };

  render() {
    const { locale, t } = this.props;
    const sliderImages = ["personal_folder_slider_phone1.png"];
    const sliderImageAlts = ["Screenshot of Personal Folder"];
    const sliderImageIds = ["img1PersonalFolderSlider"];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"personal-folder-bg.png"}
        sliderID="PersonalFolderSlider"
        sliderTitle={t("Personal Folder")}
        sliderTitleID={"textPersonalFolderSliderTitle"}
        sliderText={t("Repository of cross-boundary medical records")}
        sliderTextID="textPersonalFolderSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale} one-slide`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textChildGrowthSection1Title"
                  className="second-feature-title"
                >
                  {t("Store the medical records that you consider important")}
                </div>
                <div
                  id="textChildGrowthSection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "For example, drug, allergy and adverse drug reaction, diagnosis, surgical and procedures, as well as laboratory reports"
                  )}
                </div>
              </div>

              <div data-aos="" className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1ChildGrowthSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/personal_folder_p1_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2ChildGrowthSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/personal_folder_p1_2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section ">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textChildGrowthSection2Title"
                  className="second-feature-title"
                >
                  {t(
                    "Inform authorised healthcare professionals to access your stored medical records"
                  )}
                </div>
                <div
                  id="textChildGrowthSection2Content"
                  className="second-feature-text"
                >
                  {t("Help them better understand your health condition")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1ChildGrowthSection2"
                  data-aos="fade-up"
                  className="absolute-image back"
                  src={load(`./resources/images/personal_folder_p2_2.png`)}
                  alt=" "
                />
                <img
                  id="img2ChildGrowthSection2"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  className="absolute-image  "
                  src={load(
                    `./resources/images/${locale}/personal_folder_p2_1.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid ">
          <div className="container">
            <div className="row vertical-align">
              <div className="col-md-12">
                <div
                  id="textChildGrowthSection4Title"
                  data-aos="fade-up"
                  className="second-usage-title usage-mainTitle"
                >
                  {t("Useful Tips")}
                </div>
                <div
                  id="textChildGrowthSection4Content"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="second-usage-text usage-mainText"
                >
                  {t("How to store and share medical records")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid last-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="" className="col-sm-12 align-self-center"></div>
            </div>
            <div
              className="row ehealth-tip-step vertical-align"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="2000"
            >
              <div className="col-md-5 align-self-center">
                <img
                  className="ehealth-tip-img"
                  alt={t("Tap “Request Access Right”")}
                  src={load(
                    `./resources/images/${locale}/personal_folder_tip_1.png`
                  )}
                />
              </div>
              <div className="col-md-7 align-self-center">
                <span className="ehealth-tip-no">{t("Step 1")}</span>
                {t(
                  'Click "Add Record" and fill in the information of the cross-boundary medical records that you want to store'
                )}
              </div>
            </div>

            <div
              className="row ehealth-tip-step vertical-align"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="2000"
            >
              <div className="col-md-5">
                <img
                  className="ehealth-tip-img"
                  alt={t("'Activate Now'")}
                  src={load(
                    `./resources/images/${locale}/personal_folder_tip_2.png`
                  )}
                />
              </div>
              <div className="col-md-7">
                <span className="ehealth-tip-no">{t("Step 2")}</span>
                {t("Select the files you want to store")}
              </div>
            </div>

            <div
              className="row ehealth-tip-step vertical-align"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-duration="2000"
            >
              <div className="col-md-5">
                <img
                  className="ehealth-tip-img"
                  alt={t("Input the One Time Password (OTP)")}
                  src={load(`./resources/images/personal_folder_tip_3.png`)}
                />
              </div>
              <div className="col-md-7">
                <span className="ehealth-tip-no">{t("Step 3")}</span>
                {t(
                  "Inform authorised healthcare professionals to access your stored medical records"
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="empty-space-100"></div>
        <div
          data-aos="fade-down"
          data-aos-offset="-200"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoChildGrowth"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/8yE4nwQdE4U"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(PersonalFolderPage);
