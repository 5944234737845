import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class HealthNewsPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = ["news_slider_phone1.png", "news_slider_phone2.png"];
    const sliderImageAlts = [
      "Screenshot of Health News",
      "Screenshot of Health News",
    ];
    const sliderImageIds = ["img1HealthNewsSlider", "img2HealthNewsSlider"];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"health-news-bg.png"}
        sliderID="healthNewsSlider"
        sliderTitle={t("Health News Heading")}
        sliderTitleID={"textHealthNewsSliderTitle"}
        sliderText={t("Be your healthcare companion at all times")}
        sliderTextID="textHealthNewsSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textHealthNewsSection1Title"
                  className="second-feature-title"
                >
                  {t("Our prime focus is your health")}
                </div>
                <div
                  id="textHealthNewsSection1Content"
                  className="second-feature-text"
                >
                  {t("Keep you in touch with recent health topics")}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="imgHealthNewsSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/news_p1_1.png`)}
                  alt=" "
                />
                <img
                  id="imgHealthNewsSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(`./resources/images/${locale}/news_p1_2.png`)}
                  alt=" "
                />
                <img
                  id="imgHealthNewsSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/news_p1_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section last-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textHealthNewsSection2Title"
                  className="second-feature-title"
                >
                  {t("Sharing is caring")}
                </div>
                <div
                  id="textHealthNewsSection2Content"
                  className="second-feature-text"
                >
                  {t("Share health articles with your loved one")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="imgHealthNewsSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(`./resources/images/${locale}/news_p2_1.png`)}
                  alt=" "
                />
                <img
                  id="imgHealthNewsSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(`./resources/images/${locale}/news_p2_2.png`)}
                  alt=" "
                />
                <img
                  id="imgHealthNewsSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/news_p2_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-down"
          data-aos-offset="-200"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoHealthNews"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/T2I5ZFnyqkQ"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(HealthNewsPage);
