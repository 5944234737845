import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import Footer from "../Footer";
import "../../resources/styles/privacyGame.scss";
import GameCanvas from "./GameCanvas";

const stages = ["intro", "menu", "game", "result"];
const levels = [
  {
    id: 1,
    differences: [
      {
        x: 425,
        y: 95,
      },
      {
        x: 95,
        y: 17,
      },
      {
        x: 275,
        y: 364,
      },
    ],
  },
  {
    id: 2,
    differences: [
      {
        x: 285,
        y: 96,
      },
      {
        x: 399,
        y: 191,
      },
      {
        x: 392,
        y: 293,
      },
    ],
  },
  {
    id: 3,
    differences: [
      {
        x: 322,
        y: 41,
      },
      {
        x: 206,
        y: 269,
      },
      {
        x: 393,
        y: 276,
      },
    ],
  },
];

class PrivacyGamePage extends Component {
  static defaultProps = {
    appStoreDownloadURL: "https://apps.apple.com/app/id1514742468",
    googelPlayDownloadURL:
      "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr",
    huaweiDownloadURL: "https://appgallery.huawei.com/#/app/C104154321",
  };

  constructor(props) {
    super(props);
    this.state = {
      timer: 30,
      time: 0,
      timerInterval: null,
      stage: "intro",
      level: levels[0],
      found: [],
      isShowPopup: false,
    };

    this.handleMenu = this.handleMenu.bind(this);
    this.handleSelectLevel = this.handleSelectLevel.bind(this);
    this.handleClosePopup = this.handleClosePopup.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleStartTimer = this.handleStartTimer.bind(this);
    this.handleFound = this.handleFound.bind(this);
    this.handleWrong = this.handleWrong.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
  }

  handleMenu() {
    this.setState({
      stage: "menu",
    });
  }

  handleSelectLevel(levelIndex) {
    this.setState({
      level: levels[levelIndex],
      isShowPopup: true,
    });
  }

  handleClosePopup() {
    this.setState({
      isShowPopup: false,
    });
  }

  handleStart() {
    this.setState({
      stage: "game",
      found: [],
      isShowPopup: false,
      time: this.state.timer,
    });
    this.handleStartTimer();
  }

  handleStartTimer() {
    this.state.timerInterval = setInterval(() => {
      if (this.state.time > 0) {
        this.setState({
          time: this.state.time - 0.1,
        });
      } else {
        this.handleEnd();
      }
    }, 100);
  }

  handleFound(index) {
    if (this.state.found.indexOf(index) === -1) {
      const found = [...this.state.found, index];
      this.setState({
        found,
      });

      if (found.length === this.state.level.differences.length) {
        setTimeout(() => {
          this.handleEnd();
        }, 500);
      }
    }
  }

  handleWrong() {
    this.setState({
      time: this.state.time - 5,
    });
  }

  handleEnd() {
    this.setState({ stage: "result" });
    clearInterval(this.state.timerInterval);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.stage !== this.state.stage) {
      window.scrollTo(0, 0);
    }
  }

  detectIOS() {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }

  detectAndroid() {
    return navigator.userAgent.match(/Android/i);
  }
  detectHuawei() {
    return navigator.userAgent.match(/huawei/i);
  }
  render() {
    const { locale, t, languageCodeMap } = this.props;
    let { appStoreDownloadURL, googelPlayDownloadURL, huaweiDownloadURL } =
      this.props;
    let isIOS = this.detectIOS();
    let isAndroid = this.detectAndroid();
    let isHuawei = this.detectHuawei();
    if (locale === "en") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/id1514742468"
        : "https://apps.apple.com/us/app/id1514742468";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr&hl=en-US";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/#/app/C104154321"
        : "https://appgallery.huawei.com/#/app/C104154321";
    } else if (locale === "zh-CN") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/id1514742468"
        : "https://apps.apple.com/cn/app/id1514742468";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr&hl=zh-CN";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/#/app/C104154321"
        : "https://appgallery.huawei.com/#/app/C104154321";
    } else if (locale === "zh-HK") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/id1514742468"
        : "https://apps.apple.com/hk/app/id1514742468";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr&hl=zh-HK";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/#/app/C104154321"
        : "https://appgallery.huawei.com/#/app/C104154321";
    }

    return (
      <div
        className={`page-container page-container-${locale} page-privacy-game`}
        id="MainContent"
      >
        <div className="game-container">
          <div
            className={`popup-container ${
              this.state.isShowPopup ? "active" : ""
            }`}
          >
            <div className="shadow" onClick={this.handleClosePopup}></div>
            <div className="popup">
              <div className="title">{t("privacyGame.popup.title")}</div>
              <img
                src={load(
                  `./resources/images/privacy-game/pic-instruction.png`
                )}
                alt=""
              />
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: t("privacyGame.popup.content"),
                }}
              ></div>
              <div className="btn btn-start" onClick={this.handleStart}>
                {t("privacyGame.gameStart")}
              </div>
            </div>
          </div>
          <div className={`container stage-${this.state.stage}`}>
            {this.state.stage === "intro" && (
              <>
                <div className="title">{t("privacyGame.title")}</div>
                <img
                  className="intro desktop"
                  src={load(
                    `./resources/images/privacy-game/intro-desktop-${locale}.png`
                  )}
                  alt=""
                />
                <img
                  className="intro mobile"
                  src={load(
                    `./resources/images/privacy-game/intro-mobile-${locale}.png`
                  )}
                  alt=""
                />
                <div className="lead-in">{t("privacyGame.leadIn")}</div>
                <div className="btn btn-start" onClick={this.handleMenu}>
                  {t("privacyGame.gameStart")}
                </div>
              </>
            )}

            {this.state.stage === "menu" && (
              <>
                <div className="title">{t("privacyGame.menuTitle")}</div>
                <div className="items">
                  {levels.map((item, i) => (
                    <>
                      <div
                        className="item"
                        key={i}
                        onClick={() => this.handleSelectLevel(i)}
                      >
                        <div className="thumbnail">
                          <img
                            src={load(
                              `./resources/images/privacy-game/0${i + 1}_L.png`
                            )}
                            alt=""
                          />
                        </div>
                        <div className="name">
                          {t(`privacyGame.levels.${i}.title`)}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}

            {this.state.stage === "game" && (
              <>
                <div className="info">
                  <div className="score">
                    {this.state.level.differences.map((item, i) => {
                      if (i + 1 > this.state.found.length) {
                        return (
                          <img
                            key={i}
                            src={load(
                              `./resources/images/privacy-game/shrimp-grey.png`
                            )}
                            alt=""
                          />
                        );
                      } else {
                        return (
                          <img
                            key={i}
                            src={load(
                              `./resources/images/privacy-game/shrimp-orange.png`
                            )}
                            alt=""
                          />
                        );
                      }
                    })}
                  </div>
                  <div className="time-container">
                    <div className="time">
                      <div
                        className="progress"
                        style={{
                          width:
                            (this.state.time / this.state.timer) * 100 + "%",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="timer">
                    <img
                      src={load(`./resources/images/privacy-game/timer.png`)}
                      alt=""
                    />
                    <div className="value">
                      {this.state.time > 0 ? this.state.time.toFixed(1) : 0}
                    </div>
                  </div>
                </div>
                <div className="canvases">
                  {["L", "R"].map((direction) => (
                    <div className="canvas" key={direction}>
                      <GameCanvas
                        direction={direction}
                        level={this.state.level}
                        found={this.state.found}
                        onFound={this.handleFound}
                        onWrong={this.handleWrong}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}

            {this.state.stage === "result" && (
              <>
                <div className="title">
                  <img
                    src={load(
                      `./resources/images/privacy-game/game-result-${locale}.png`
                    )}
                    alt=""
                  />
                </div>
                <div className="founds">
                  {t("privacyGame.foundPrefix")}
                  <span className="highlight">{this.state.found.length}</span>
                  {t("privacyGame.foundSuffix")}
                  {locale === "en"
                    ? this.state.found.length > 1
                      ? "s."
                      : "."
                    : ""}
                </div>
                <div className="result-text">
                  <img
                    src={load(
                      `./resources/images/privacy-game/result-${this.state.found.length}-${locale}.png`
                    )}
                    alt=""
                  />
                </div>
                <div className="btns">
                  <a
                    className="btn btn-share"
                    href={`https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=${encodeURIComponent(
                      "https://" + window.location.host + "/privacy-game-result-" +
                        this.state.found.length +
                        "-" +
                        locale +
                        ".html?lang=" +
                        locale +
                        "&display=popup&ref=plugin&src=share_button"
                    )}`}
                    target="_blank"
                  >
                    {t("privacyGame.shareResult")}
                  </a>
                  <div className="btn" onClick={this.handleStart}>
                    {t("privacyGame.restart")}
                  </div>
                  <div className="btn" onClick={this.handleMenu}>
                    {t("privacyGame.nextLevel")}
                  </div>
                </div>
                <div className="remind">
                  {t("privacyGame.dragDownToView")}
                  <img
                    src={load(
                      `./resources/images/privacy-game/ico-arrow-down.png`
                    )}
                    alt=""
                  />
                </div>
                <div className="tips">
                  {this.state.level.differences.map((item, i) => {
                    return (
                      <div className="tip">
                        <div className="content">
                          <div className="title">
                            {t("privacyGame.tipPrefix")}
                            {i + 1}：
                            {t(
                              `privacyGame.levels.${
                                this.state.level.id - 1
                              }.differences.${i}.title`
                            )}
                          </div>
                          <div
                            className="detail"
                            dangerouslySetInnerHTML={{
                              __html: t(
                                `privacyGame.levels.${
                                  this.state.level.id - 1
                                }.differences.${i}.detail`
                              ),
                            }}
                          ></div>
                          {this.state.level.id === 3 && i === 2 ? (
                            <div className="app-download">
                              <a
                                id="linkHomePhoneSectionDownloadAppStore"
                                href="#"
                                onClick={() => {
                                  window.open(appStoreDownloadURL, "_blank");
                                }}
                                tabIndex="0"
                              >
                                <span>
                                  <img
                                    id="btnHomePhoneSectionDownloadAppStore"
                                    src={load(
                                      `./resources/images/${locale}/app_store.png`
                                    )}
                                    alt={t("Download at App Store")}
                                  />
                                </span>
                              </a>
                              <a
                                id="linkHomePhoneSectionDownloadGooglePlay"
                                href="#"
                                onClick={() => {
                                  window.open(googelPlayDownloadURL, "_blank");
                                }}
                                tabIndex="0"
                              >
                                <span>
                                  <img
                                    id="btnHomePhoneSectionDownloadGooglePlay"
                                    src={load(
                                      `./resources/images/${locale}/google_play.png`
                                    )}
                                    alt={t("Download at Google Play")}
                                  />
                                </span>
                              </a>
                              <a
                                id="linkHomePhoneSectionDownloadHuawei"
                                href="#"
                                onClick={() => {
                                  window.open(huaweiDownloadURL, "_blank");
                                }}
                                tabIndex="0"
                              >
                                <span>
                                  <img
                                    id="btnHomePhoneSectionDownloadHuawei"
                                    src={load(
                                      `./resources/images/${locale}/huawei.png`
                                    )}
                                    alt={t("Download at Huawei App Gallery")}
                                  />
                                </span>
                              </a>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="image">
                          <img
                            src={load(
                              `./resources/images/privacy-game/tips${
                                this.state.level.id
                              }-${i + 1}${
                                this.state.found.includes(i) ? "-found" : ""
                              }.png`
                            )}
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PageHOC(PrivacyGamePage);
