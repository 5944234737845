import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class NotFoundPage extends Component {
  static defaultProps = {
    apkDownloadURL: "https://app.ehealth.gov.hk/static/app/ehealthapp.apk",
  };

  render() {
    let { apkDownloadURL } = this.props;
    const { locale, t } = this.props;
    return (
      <div
        className={`download-apk-page page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="container download-apk-container">
          <div className=" main-title">
            <div className="main-title-text">
              {t(
                "Install the Android Application Package (APK) file of the eHealth App"
              )}
            </div>
            <img
              id="DownloadApkGirl"
              src={load(`./resources/images/download-girl-graphic.png`)}
              alt=" "
            />
          </div>
          <div className="download-apk-blue">
            <b>
              {t(
                "It is recommended to install the eHealth App from Google Play or Huawei AppGallery."
              )}
            </b>
            {t(
              "Download the APK file to install the eHealth App only when Google Play and Huawei AppGallery cannot be accessed."
            )}
            <br />
            <br />
            {t(
              "This website is the only authorised channel to download the APK file for installing the eHealth App. To protect your information, please do not download the APK file from other channels to install the eHealth App."
            )}
          </div>
          <div className="download-apk-mama-container">
            <img
              id="DownloadApkMama"
              src={load(`./resources/images/download-mama-graphic.png`)}
              alt=" "
            />
          </div>
          <div className="download-apk-grey row">
            <div className="col-sm-4">
              <img
                className="desktop-show"
                src={load(
                  `./resources/images/${locale}/download-apk-phone.png`
                )}
                alt=" "
              />
              <img
                className="mobile-show"
                src={load(`./resources/images/download-apk-mobile-qrcode.png`)}
                alt=" "
              />

              <a
                id="linkHomePhoneSectionDownloadApk"
                className="download-apk-button"
                href="#"
                onClick={() => {
                  window.open(apkDownloadURL, "_blank");
                }}
                tabIndex="0"
              >
                <span>
                  <img
                    id="btnHomePhoneSectionDownloadApk"
                    src={load(`./resources/images/${locale}/apk-dl-btn.png`)}
                    alt={t("Download at Huawei App Gallery")}
                  />
                </span>
              </a>
            </div>

            <div className="col-sm-8">
              <h3>{t("Download Instructions")}</h3>
              <h4>{t("Android 8.0 or higher")}</h4>
              <ol>
                <li>{t('Select "Settings"')}</li>
                <li>{t('Select "Apps & notification"')}</li>
                <li>
                  {t(
                    'Select "Special access" (Tap the "Menu icon" at the right top corner and then select "Special access" if it is not shown in the list)'
                  )}
                </li>
                <li>{t('Select "Install unknown apps"')}</li>
                <li>
                  {t(
                    "Locate and select the particular app that you used to open the installation package in the list (e.g. Chrome browser app or File Manager app)"
                  )}
                </li>
                <li>{t('Enable option "Allow from this source"')}</li>
                <li>
                  {t(
                    "Tap or scan the QR code on the right to download the APK file of the eHealth App"
                  )}
                </li>
                <li>{t("Open the downloaded APK file to install")}</li>
                <li>
                  {t(
                    'Repeat steps 1-5 above and then disable option  "Allow from this source"'
                  )}
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(NotFoundPage);
