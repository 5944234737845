import React, { Component } from 'react';
import * as PIXI from 'pixi.js';
import { TweenMax, Power2 } from 'gsap';

class GameCanvas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			circles: [],
		};
		this.app = new PIXI.Application({
			width: 570,
			height: 428,
			transparent: true,
		});
		this.loader = new PIXI.Loader();
		this.pixi_cnt = null;
		this.updatePixiCnt = this.updatePixiCnt.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.found !== prevProps.found) {
			this.props.found.forEach((found) => {
				const isNew = prevProps.found.indexOf(found) === -1;

				if (isNew) {
					let circle = this.state.circles[found];
					circle.alpha = 1;
					circle.width = 0;
					circle.height = 0;
					TweenMax.to(circle, 0.5, {
						width: 50,
						height: 50,
						ease: 'elastic.out(1, 0.3)',
					});
					let audio = new Audio(
						require('../../resources/audios/correct.mp3')
					);
					audio.play();
				}
			});
		}
	}

	updatePixiCnt(element) {
		this.pixi_cnt = element;
		if (this.pixi_cnt && this.pixi_cnt.children.length <= 0) {
			this.pixi_cnt.appendChild(this.app.view);
			this.setup();
		}
	}

	setup = () => {
		this.loader
			.add(
				'bg',
				require(`../../resources/images/privacy-game/0${this.props.level.id}_${this.props.direction}.png`)
			)
			.add(
				'circle',
				require('../../resources/images/privacy-game/circle.png')
			)
			.add(
				'text-reduce',
				require('../../resources/images/privacy-game/text-reduce-5.png')
			)
			.load(() => {
				// Load background
				console.log(this.loader.resources['bg']);
				let bg = new PIXI.Sprite(this.loader.resources['bg'].texture);
				bg.anchor.set(0, 0);
				bg.position.set(0, 0);
				bg.interactive = true;
				this.app.stage.addChild(bg);
				bg.on('pointerdown', (event) => {
					const x = event.data.global.x;
					const y = event.data.global.y;

					let textReduce = new PIXI.Sprite(
						this.loader.resources['text-reduce'].texture
					);
					textReduce.anchor.set(0.5, 0.5);
					textReduce.position.set(x, y);
					textReduce.width = 50;
					textReduce.height = 40;
					this.app.stage.addChild(textReduce);

					let audio = new Audio(
						require('../../resources/audios/wrong.mp3')
					);
					audio.play();

					TweenMax.to(textReduce, 0.1, {
						x: '-=10',
						yoyo: true,
						repeat: 3,
						ease: Power2.easeInOut,
					});

					TweenMax.to(textReduce, 0.5, {
						alpha: 1,
						delay: 0.5,
						onComplete: () => {
							this.app.stage.removeChild(textReduce);
						},
					});

					setTimeout(() => {
						this.app.stage.removeChild(textReduce);
					}, 800);

					this.props.onWrong();
				});

				// Load circles
				this.props.level.differences.forEach((difference, i) => {
					let circle = new PIXI.Sprite(
						this.loader.resources['circle'].texture
					);
					circle.anchor.set(0.5, 0.5);
					circle.position.set(
						difference.x + circle.width / 2,
						difference.y + circle.height / 2
					);
					circle.alpha = 0;
					circle.interactive = true;
					circle.hitArea = new PIXI.Circle(0, 0, 50);

					circle.on('pointerdown', () => {
						this.props.onFound(i);
					});
					this.state.circles.push(circle);
					this.app.stage.addChild(circle);
				});
			});
	};

	render() {
		return <div ref={this.updatePixiCnt} />;
	}
}

export default GameCanvas;
