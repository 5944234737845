import React, { Component } from 'react'
import {NavLink} from "react-router-dom";
import load from '../../../ImageLoder';
import PageHOC from '../../../PageHOC';
import Footer from '../../Footer';

class PromotionalVideos extends Component {
    render() {
        const {locale, t} = this.props;
     
        return (
            <div className={`promulgation-promotional-videos-page page-container page-container-${locale}`} id="MainContent">
                <div className="static_page_header">
                    <div className="container-full">
                        <div className="row vertical-align">
                            <div className="col-sm-12">
                                <img id="imgCovidQRTopBannerImage" data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-offset="0" src={load(`./resources/images/landing-slider-img-1.jpg`)} alt=" "/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="container">
                        <div data-aos="fade-up" data-aos-delay="200" data-aos-offset="-200"  className='content-title promulgation-page-title'>
                            <img className='content-title-icon' src={load(`./resources/images/promulgation/landing/promulgation-landing-promo-video-icon.png`)} alt="" />
                            <span className='content-title-text'>{t('Promotional Videos title')}</span>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="container introduction-text-container">
                        <div className="row vertical-align">
                            <div data-aos="fade-up" data-aos-delay="400" data-aos-offset="-200"  className="col-md-12 col-sm-12">
                                <div id="" className="promulgation-video-intro">
                                    {t("promulgation-video-intro")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid">
                    <div className="container video-title-container">
                        <div data-aos="fade-up" data-aos-delay="400" data-aos-offset="-200" className='video-title'>
                            <span className='content-title-text'>{t('Full Version')}</span>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="container promotional-videos-full">
                        <div className="row vertical-align">
                            <div data-aos="fade-up"  data-aos-offset="0" className="center-div" alt={t("Video")}>        
                                <div ref={this.youtubeContainer} className="second-youtube-container">
                                    <iframe id="videoHealthNews" key={locale} className="second-responsive-iframe" title={t("Video des")} 
                                        src="https://www.youtube.com/embed/yeXl2NwSPGg" alt={t("Video")} tabIndex="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="container-fluid">
                    <div className="container video-title-container">
                        <div data-aos="fade-up" data-aos-delay="200" data-aos-offset="-200" className='video-title'>
                            <span className='content-title-text'>{t('30s Version')}</span>
                        </div>
                    </div>
                </div>

                <div className="container-fluid" > 
                    <div className="container">
                        <div data-aos="fade-up" data-aos-delay="400" data-aos-offset="-400" className="row vertical-align">
                            <div className="col-md-6 col-sm-12">
                                <div  data-aos-offset="-200" className="center-div" alt={t("Video")}>        
                                    <div ref={this.youtubeContainer} className="second-youtube-container">
                                        <iframe id="videoHealthNews" key={locale} className="second-responsive-iframe" title={t("Video des")} 
                                            src="https://www.youtube.com/embed/nk8m64dDG94" alt={t("Video")} tabIndex="0"></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div  data-aos-offset="-200" className="center-div" alt={t("Video")}>        
                                    <div ref={this.youtubeContainer} className="second-youtube-container">
                                        <iframe id="videoHealthNews" key={locale} className="second-responsive-iframe" title={t("Video des")} 
                                            src="https://www.youtube.com/embed/jzYwEZQmrR4" alt={t("Video")} tabIndex="0"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="empty-space-100"></div>

                <div className="container-fluid">
                    <div className="container video-title-container">
                        <div data-aos="fade-up" data-aos-delay="200" data-aos-offset="-200" className='video-title'>
                            <span className='content-title-text'>{t('Full Version')}</span><br/>
                            <span className='content-title-sub-text'>{t("(Chinese Version Only)")}</span>
                        </div>
                    </div>
                </div>

                <div className="container-fluid" > 
                    <div className="container">
                        <div data-aos="fade-up" data-aos-delay="400" data-aos-offset="-400" className="row vertical-align">
                            <div className="col-md-2"></div>
                            <div className="col-md-8 col-sm-12">
                                <div  data-aos-offset="-200" className="center-div" alt={t("Video")}>        
                                    <div ref={this.youtubeContainer} className="second-youtube-container half">
                                        <iframe id="videoHealthNews" key={locale} className="second-responsive-iframe" title={t("Video des")} 
                                            src="https://www.youtube.com/embed/Ui6mHQhokNQ" alt={t("Video")} tabIndex="0"></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>

                        </div>
                    </div>
                </div>

<div className="empty-space-100"></div>

<div className="container-fluid">
    <div className="container video-title-container">
        <div data-aos="fade-up" data-aos-delay="200" data-aos-offset="-200" className='video-title'>
            <span className='content-title-text'>{t('Behind the Scenes')}</span><br/>
            <span className='content-title-sub-text'>{t("(Chinese Version Only)")}</span>
        </div>
    </div>
</div>

<div className="container-fluid" > 
    <div className="container">
        <div data-aos="fade-up" data-aos-delay="400" data-aos-offset="-400" className="row vertical-align">
            <div className="col-md-2"></div>
            <div className="col-md-8 col-sm-12">
                <div  data-aos-offset="-200" className="center-div" alt={t("Video")}>        
                    <div ref={this.youtubeContainer} className="second-youtube-container half">
                        <iframe id="videoHealthNews" key={locale} className="second-responsive-iframe" title={t("Video des")} 
                            src="https://www.youtube.com/embed/_Fe5_Ou7ViQ" alt={t("Video")} tabIndex="0"></iframe>
                    </div>
                </div>
            </div>
            <div className="col-md-2"></div>

        </div>
    </div>
</div>


                <div className="promulgation-back" data-aos="fade-up" data-aos-delay="200" data-aos-offset="-200">
                    <a href="javascript:history.back()">{t("Back")}</a>
                </div>

                <Footer {...this.props} />

            </div>
        )
    }
}

export default PageHOC(PromotionalVideos);