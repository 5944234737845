import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class EhealthProPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = ["ehealthpro_acc_mgmt_slider_phone1.png"];
    const sliderImageAlts = ["Screenshot of eHealthPro and Medications"];
    const sliderImageIds = ["img1eHealthProSlider"];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"pro-acc-mgmt-bg.png"}
        sliderID="eHealthProSlider"
        sliderTitle={t("Pro Account Management")}
        sliderTitleID={"texteHealthProAccSliderTitle"}
        sliderText={t("Pro Simplify manage your account with ease")}
        sliderTextID="texteHealthProSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale} ehealthpro-color single-phone`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid  ehealthpro-bg">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="texteHealthProSection1Title"
                  className="second-feature-title"
                >
                  {t("Check the relationship with your Healthcare Providers")}
                </div>
                <div
                  id="texteHealthProSection1Content"
                  className="second-feature-text"
                >
                  {t(
                    'Tap "Existing", "Pending" or "Expired" to view your request record'
                  )}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1eHealthProAccSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="100"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_acc_p1_1.png`
                  )}
                  alt={t("Make Login to eHRSS Safe, Simple and Fast")}
                />
                <img
                  id="img2eHealthProAccSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_acc_p1_2.png`
                  )}
                  alt=" "
                />
                <img
                  id="img3eHealthProAccSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="400"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_acc_p1_3.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid ehealthpro-2nd-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="" className="col-sm-12 align-self-center">
                <div className="ehealthpro-title">
                  {t("Request access right in a few simple steps")}
                </div>
              </div>
            </div>
            <div className="row ehealthpro-step vertical-align">
              <div className="col-md-4 align-self-center">
                <img
                  className="pro-feature-img"
                  alt={t("Tap “Request Access Right”")}
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_acc_request_access_step_1.png`
                  )}
                />
              </div>
              <div className="col-md-8 align-self-center">
                <span className="pro-step-no">{t("Step 1")}</span>
                {t('Tap "Request Access Right"')}
              </div>
            </div>

            <div className="row ehealthpro-step vertical-align">
              <div className="col-md-4">
                <img
                  className="pro-feature-img"
                  alt={t("'Activate Now'")}
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_acc_request_access_step_2.png`
                  )}
                />
              </div>
              <div className="col-md-8">
                <span className="pro-step-no">{t("Step 2")}</span>
                {t("Select the relevant Healthcare Providers")}
              </div>
            </div>

            <div className="row ehealthpro-step vertical-align">
              <div className="col-md-4">
                <img
                  className="pro-feature-img"
                  alt={t("Input the One Time Password (OTP)")}
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_acc_request_access_step_3.png`
                  )}
                />
              </div>
              <div className="col-md-8">
                <span className="pro-step-no">{t("Step 3")}</span>
                {t(
                  "Authorise to provide personal information to the User Administrator of the Healthcare Provider"
                )}
              </div>
            </div>

            <div className="row ehealthpro-step vertical-align done-step">
              <div className="col-md-4">
                <img
                  className="pro-feature-img"
                  alt={t("You can use eHealth Pro now!")}
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_acc_request_access_step_4.png`
                  )}
                />
              </div>
              <div className="col-md-8">
                <span className="pro-step-no">{t("acc mgmt DONE")}</span>
                <span className="pro-donestep-des">
                  {t(
                    "Approved requests will be listed under “Existing” tab and labelled as “My HCP”."
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid ehealthpro-bg">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textMyFamilySection2Title"
                  className="second-feature-title"
                >
                  {t("Easy managing your contact information")}
                </div>
                <div
                  id="textMyFamilySection2Content"
                  className="second-feature-text"
                >
                  {t("Keep your contact information up to date")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1eHealthProSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_acc_p2_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2eHealthProSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_acc_p2_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid  ehealthpro-light-bg ">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="texteHealthProSection1Title"
                  className="second-feature-title"
                >
                  {t("Simplify reset password for your eHRSS account")}
                </div>
                <div
                  id="texteHealthProSection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "Rest password with one tap and receive the new password via your communication means"
                  )}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1eHealthProSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_acc_p3_1.png`
                  )}
                  alt={t("Make Login to eHRSS Safe, Simple and Fast")}
                />
                <img
                  id="img2eHealthProSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_acc_p3_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(EhealthProPage);
