import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import Footer from "../Footer";

class EHealthFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [
        {
          slug: "general",
          title: "General",
        },
        {
          slug: "getstarted",
          title: "Get Started/ Login",
        },
        {
          slug: "account",
          title: "eHealth Account Management",
        },
        {
          slug: "family",
          title: "faq My Family",
        },
        {
          slug: "functions",
          title: "Functions",
        },
        {
          slug: "crossBoundaryHealthRecord",
          title: "Cross-boundary Health Record",
        },
        {
          slug: "personalFolder",
          title: "Personal Folder",
        },
        {
          slug: "technical",
          title: "Technical",
        },
        {
          slug: "enquiry",
          title: "Enquiry",
        },
      ],
      questions: [
        {
          isOpen: false,
          sectionSlug: "general",
          slug: "objectives",
          question: "faq-section1-q01-question",
          answer: "faq-section1-q01-answer",
        },
        {
          isOpen: false,
          sectionSlug: "general",
          slug: "functions",
          question: "faq-section1-q02-question",
          answer: "faq-section1-q02-answer",
        },
        {
          isOpen: false,
          sectionSlug: "general",
          slug: "not-registered",
          question: "faq-section1-q03-question",
          answer: "faq-section1-q03-answer",
        },
        {
          isOpen: false,
          sectionSlug: "getstarted",
          slug: "username-pw",
          question: "faq-section2-q01-question",
          answer: "faq-section2-q01-answer",
        },
        {
          isOpen: false,
          sectionSlug: "getstarted",
          slug: "new-device",
          question: "faq-section2-q02-question",
          answer: "faq-section2-q02-answer",
        },
        {
          isOpen: false,
          sectionSlug: "getstarted",
          slug: "correct-personal-info",
          question: "faq-section2-q03-question",
          answer: "faq-section2-q03-answer",
        },
        {
          isOpen: false,
          sectionSlug: "account",
          slug: "information",
          question: "faq-section3-q01-question",
          answer: "faq-section3-q01-answer",
        },
        {
          isOpen: false,
          sectionSlug: "account",
          slug: "sharing-consent",
          question: "faq-section3-q02-question",
          answer: "faq-section3-q02-answer",
        },
        {
          isOpen: false,
          sectionSlug: "family",
          slug: "manage-acc",
          question: "faq-section4-q01-question",
          answer: "faq-section4-q01-answer",
        },
        {
          isOpen: false,
          sectionSlug: "family",
          slug: "under-16",
          question: "faq-section4-q02-question",
          answer: "faq-section4-q02-answer",
        },
        {
          isOpen: false,
          sectionSlug: "family",
          slug: "manage-family-health",
          question: "faq-section4-q03-question",
          answer: "faq-section4-q03-answer",
        },
        {
          isOpen: false,
          sectionSlug: "functions",
          slug: "doctor-search",
          question: "faq-section5-q01-question",
          answer: "faq-section5-q01-answer",
        },
        {
          isOpen: false,
          sectionSlug: "functions",
          slug: "electronic-health-record",
          question: "faq-section5-q02-question",
          answer: "faq-section5-q02-answer",
        },
        {
          isOpen: false,
          sectionSlug: "functions",
          slug: "appointments",
          question: "faq-section5-q03-question",
          answer: "faq-section5-q03-answer",
        },
        {
          isOpen: false,
          sectionSlug: "functions",
          slug: "investigations",
          question: "faq-section5-q04-question",
          answer: "faq-section5-q04-answer",
        },
        {
          isOpen: false,
          sectionSlug: "functions",
          slug: "vaccine-records",
          question: "faq-section5-q05-question",
          answer: "faq-section5-q05-answer",
        },
        {
          isOpen: false,
          sectionSlug: "functions",
          slug: "covid-19-records",
          question: "faq-section5-q06-question",
          answer: "faq-section5-q06-answer",
        },
        {
          isOpen: false,
          sectionSlug: "functions",
          slug: "health-programme",
          question: "faq-section5-q07-question",
          answer: "faq-section5-q07-answer",
        },
        {
          isOpen: false,
          sectionSlug: "functions",
          slug: "child-growth-record",
          question: "faq-section5-q08-question",
          answer: "faq-section5-q08-answer",
        },
        {
          isOpen: false,
          sectionSlug: "functions",
          slug: "user-contributed-content",
          question: "faq-section5-q09-question",
          answer: "faq-section5-q09-answer",
        },
        {
          isOpen: false,
          sectionSlug: "functions",
          slug: "delete-app",
          question: "faq-section5-q10-question",
          answer: "faq-section5-q10-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "what-is-cbhr",
          question: "faq-cbhr-q1-question",
          answer: "faq-cbhr-q1-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-who-can-apply",
          question: "faq-cbhr-q2-question",
          answer: "faq-cbhr-q2-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-apply-for-family",
          question: "faq-cbhr-q3-question",
          answer: "faq-cbhr-q3-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-how-to-apply",
          question: "faq-cbhr-q4-question",
          answer: "faq-cbhr-q4-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-hcp",
          question: "faq-cbhr-q5-question",
          answer: "faq-cbhr-q5-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-record-included",
          question: "faq-cbhr-q6-question",
          answer: "faq-cbhr-q6-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-why-record-not-included",
          question: "faq-cbhr-q7-question",
          answer: "faq-cbhr-q7-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-take-time",
          question: "faq-cbhr-q8-question",
          answer: "faq-cbhr-q8-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-progress",
          question: "faq-cbhr-q9-question",
          answer: "faq-cbhr-q9-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-completed",
          question: "faq-cbhr-q10-question",
          answer: "faq-cbhr-q10-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-forget-password",
          question: "faq-cbhr-q11-question",
          answer: "faq-cbhr-q11-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-view-self-record",
          question: "faq-cbhr-q12-question",
          answer: "faq-cbhr-q12-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-who-can-view-record",
          question: "faq-cbhr-q13-question",
          answer: "faq-cbhr-q13-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-qr-record-days",
          question: "faq-cbhr-q14-question",
          answer: "faq-cbhr-q14-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-hcp-record-days",
          question: "faq-cbhr-q15-question",
          answer: "faq-cbhr-q15-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-duplicate-apply",
          question: "faq-cbhr-q16-question",
          answer: "faq-cbhr-q16-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-privacy",
          question: "faq-cbhr-q17-question",
          answer: "faq-cbhr-q17-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-why-not-provide",
          question: "faq-cbhr-q18-question",
          answer: "faq-cbhr-q18-answer",
        },
        {
          isOpen: false,
          sectionSlug: "crossBoundaryHealthRecord",
          slug: "cbhr-enquiry",
          question: "faq-cbhr-q19-question",
          answer: "faq-cbhr-q19-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "what-is-personal-folder",
          question: "faq-personal-folder-q1-question",
          answer: "faq-personal-folder-q1-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "personal-folder-hcp-record",
          question: "faq-personal-folder-q2-question",
          answer: "faq-personal-folder-q2-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "personal-folder-record-included",
          question: "faq-personal-folder-q3-question",
          answer: "faq-personal-folder-q3-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "personal-folder-apply-for-family",
          question: "faq-personal-folder-q4-question",
          answer: "faq-personal-folder-q4-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "personal-folder-file-format",
          question: "faq-personal-folder-q5-question",
          answer: "faq-personal-folder-q5-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "personal-folder-limitation",
          question: "faq-personal-folder-q6-question",
          answer: "faq-personal-folder-q6-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "personal-folder-Language",
          question: "faq-personal-folder-q7-question",
          answer: "faq-personal-folder-q7-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "personal-folder-fail-notification",
          question: "faq-personal-folder-q8-question",
          answer: "faq-personal-folder-q8-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "personal-folder-who-can-view",
          question: "faq-personal-folder-q9-question",
          answer: "faq-personal-folder-q9-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "personal-folder-can-i-view",
          question: "faq-personal-folder-q10-question",
          answer: "faq-personal-folder-q10-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "personal-folder-hcp-view",
          question: "faq-personal-folder-q11-question",
          answer: "faq-personal-folder-q11-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "personal-folder-paper-record",
          question: "faq-personal-folder-q12-question",
          answer: "faq-personal-folder-q12-answer",
        },
        {
          isOpen: false,
          sectionSlug: "personalFolder",
          slug: "personal-folder-delete-record",
          question: "faq-personal-folder-q13-question",
          answer: "faq-personal-folder-q13-answer",
        },
        {
          isOpen: false,
          sectionSlug: "technical",
          slug: "mobile-devices",
          question: "faq-section6-q01-question",
          answer: "faq-section6-q01-answer",
        },
        {
          isOpen: false,
          sectionSlug: "technical",
          slug: "back-up",
          question: "faq-section6-q02-question",
          answer: "faq-section6-q02-answer",
        },
        {
          isOpen: false,
          sectionSlug: "enquiry",
          slug: "contact",
          question: "faq-section7-q01-question",
          answer: "faq-section7-q01-answer",
        },
      ],
    };
  }
  toggleQuestion(sectionSlug, slug) {
    const newQuestions = [...this.state.questions];
    const questionIndex = newQuestions.findIndex(
      (question) =>
        question.slug === slug && question.sectionSlug === sectionSlug
    );
    if (questionIndex === -1) return;
    newQuestions[questionIndex].isOpen = !newQuestions[questionIndex].isOpen;
    this.setState({
      ...this.state,
      questions: newQuestions,
    });
  }

  toggleSectionQuestions(slug) {
    const newQuestions = [...this.state.questions];
    const sectionQuestions = newQuestions.filter(
      (question) => question.sectionSlug === slug
    );
    const sectionOpen = sectionQuestions.some((question) => !question.isOpen);
    sectionQuestions.forEach((question) => {
      question.isOpen = sectionOpen;
    });
    this.setState({
      ...this.state,
      questions: newQuestions,
    });
  }

  toggleAll() {
    const newQuestions = [...this.state.questions];
    const open = newQuestions.some((question) => !question.isOpen);
    newQuestions.forEach((question) => {
      question.isOpen = open;
    });
    this.setState({
      ...this.state,
      questions: newQuestions,
    });
  }

  scrollToQuestion() {
    const navHeight = window.innerWidth > 1260 ? 80 : 64;
    const offsetTop = navHeight + 20;
    let anchor =
      this.getParameterByName("section") ?? window.location.href.split("#")[1];
    anchor = anchor?.split("?")[0];
    if (!anchor) return;

    const type = anchor?.includes("_") ? "question" : "section";
    const sectionSlug = type === "question" ? anchor.split("_")[0] : anchor;
    if (!sectionSlug) return;

    if (type === "question") {
      const questionSlug = anchor.split("_")[1]?.split("?")[0];
      console.log({ questionSlug });
      const questionElement = document.getElementById(
        `faq-question-container-${sectionSlug}-${questionSlug}`
      );
      if (!questionElement) return;

      setTimeout(() => {
        this.toggleQuestion(sectionSlug, questionSlug);
        window.scrollTo({
          top: questionElement.offsetTop - offsetTop,
          behavior: "smooth",
        });
      }, 100);

      return;
    }

    if (type === "section") {
      this.toggleSectionQuestions(sectionSlug);
      const sectionElement = document.getElementById(
        `faq-section-${sectionSlug}`
      );
      if (!sectionElement) return;
      setTimeout(() => {
        window.scrollTo({
          top: sectionElement.offsetTop - offsetTop,
          behavior: "smooth",
        });
      }, 100);

      return;
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  componentDidMount() {
    this.scrollToQuestion();
  }

  render() {
    const { locale, t } = this.props;
    let { eHealthSite, registerLink, ConsentLink } = this.props;
    if (locale === "en") {
      registerLink =
        "https://www.ehealth.gov.hk/en/you-and-your-family/how-to-register/index.html";
      eHealthSite = "https://app.ehealth.gov.hk/app/index.html?lang=en";
      ConsentLink =
        "https://www.ehealth.gov.hk/en/you-and-your-family/sharing-consent/index.html";
    } else if (locale === "zh-CN") {
      registerLink =
        "https://www.ehealth.gov.hk/sc/you-and-your-family/how-to-register/index.html";
      eHealthSite = "https://app.ehealth.gov.hk/app/index.html?lang=sc";
      ConsentLink =
        "https://www.ehealth.gov.hk/sc/you-and-your-family/sharing-consent/index.html";
    } else if (locale === "zh-HK") {
      registerLink =
        "https://www.ehealth.gov.hk/tc/you-and-your-family/how-to-register/index.html";
      eHealthSite = "https://app.ehealth.gov.hk/app/index.html?lang=tc";
      ConsentLink =
        "https://www.ehealth.gov.hk/tc/you-and-your-family/sharing-consent/index.html";
    }

    const answers = {
      "faq-section1-q02-answer": (
        <>
          {t("faq-section1-q02-answer-p1")}
          <br />
          {t("faq-section1-q02-answer-p2")}
          <a href={eHealthSite} target="_blank">
            {t("faq-section1-q02-answer-p3")}
          </a>
          {t("faq-section1-q02-answer-p4")}
        </>
      ),
      "faq-section1-q03-answer": (
        <>
          {t("faq-section1-q03-answer-p1")}

          <a href={registerLink} target="_blank">
            {registerLink}
          </a>
          {t("faq-section1-q03-answer-p3")}
        </>
      ),
      "faq-section4-q03-answer": (
        <>
          {t("faq-section4-q03-answer-p1")}
          <ul className="faq-list">
            <li>{t("faq-section4-q03-answer-p2")}</li>
          </ul>
        </>
      ),
      "faq-cbhr-q7-answer": (
        <>
          {t("faq-cbhr-q7-answer-p1")}
          <a href={ConsentLink} target="_blank">
            {ConsentLink}
          </a>
          {t("faq-cbhr-q7-answer-p2")}
        </>
      ),
      "faq-personal-folder-q10-answer": (
        <>
          {t("faq-personal-folder-q10-answer")}
          <a href={ConsentLink} target="_blank">
            {ConsentLink}
          </a>
        </>
      ),
    };

    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="static_page_header static_page_header_download">
          <div
            className="static_page_header_bg"
            style={{
              background: `url(${load(
                "./resources/images/faq-banner-bg.jpg"
              )}) no-repeat 0%`,
            }}
          ></div>
          <div className="container ">
            <div className="row vertical-align">
              <div className="col-sm-12 download-banner-graphic">
                <img
                  id="imgCovidAdolescentTopBannerImage"
                  src={load(
                    `./resources/images/${locale}/faq-banner-graphic.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container faq-container">
          <div className="row">
            <div className="faq-expand-all">
              <span className="faq-expand" onClick={() => this.toggleAll()}>
                {this.state.questions.some((question) => !question.isOpen)
                  ? t("Expand all section")
                  : t("Collapse all section")}
              </span>
            </div>
            {this.state.sections.map((section) => {
              return (
                <div id={`faq-section-${section.slug}`} className="faq-section">
                  <div className="faq-title">
                    <h3>{t(section.title)}</h3>
                    <span
                      className="faq-expand"
                      onClick={() => this.toggleSectionQuestions(section.slug)}
                    >
                      {this.state.questions.some(
                        (question) =>
                          question.sectionSlug === section.slug &&
                          !question.isOpen
                      )
                        ? t("Expand this section")
                        : t("Collapse this section")}
                    </span>
                  </div>
                  {this.state.questions
                    .filter((question) => question.sectionSlug === section.slug)
                    .map((question) => {
                      return (
                        <div
                          id={`faq-question-container-${question.sectionSlug}-${question.slug}`}
                          className="faq-question-container"
                        >
                          <div
                            className="faq-question"
                            onClick={() =>
                              this.toggleQuestion(
                                question.sectionSlug,
                                question.slug
                              )
                            }
                          >
                            {t(question.question)}
                            <img
                              className="faq-expand-icon"
                              src={load(
                                `${
                                  question.isOpen
                                    ? "./resources/images/collapse-button.png"
                                    : "./resources/images/collapse-button-open.png"
                                }`
                              )}
                            />
                          </div>
                          {question.isOpen && (
                            <div className="faq-answer">
                              {answers[question.answer] || t(question.answer)}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

document.addEventListener("DOMContentLoaded", () => {
  let str = window.location.href;
  str = str.split("?")[0].split("#").reverse()[0];
  if (str == "faq-section5") {
    console.log(str);
    var appointment_faq = document
      .getElementById("faq-section5-03")
      .getElementsByClassName("faq-question")[0];
    appointment_faq.click();
    console.log(appointment_faq);
  }
});
export default PageHOC(EHealthFaq);
