import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class CaregiverPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = [
      "caregiver_slider_phone1.png",
      "caregiver_slider_phone2.png",
      "caregiver_slider_phone3.png",
    ];
    const sliderImageAlts = [
      "Screenshot of My Family",
      "Screenshot of My Family",
      "Screenshot of My Family",
    ];
    const sliderImageIds = [
      "img1MyFamilySlider",
      "img2MyFamilySlider",
      "img3MyFamilySlider",
    ];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"caregiver-bg.png"}
        sliderID="myFamilySlider"
        sliderTitle={t("My Family Header")}
        sliderTitleID={"textMyFamilySliderTitle"}
        sliderText={t("The health of your family matters")}
        sliderTextID="textMyFamilySliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        {phoneSlider}
        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textMyFamilySection1Title"
                  className="second-feature-title"
                >
                  {t("Keep the health of your family within easy reach")}
                </div>
                <div
                  id="textMyFamilySection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "Support management of medication, allergy and vaccine records for your family"
                  )}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1MyFamilySection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  className="absolute-image front"
                  src={load(`./resources/images/caregiver_p1_2.png`)}
                  alt=" "
                />
                <img
                  id="img2MyFamilySection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/${locale}/caregiver_p1_1.png`)}
                  alt=" "
                />
                <img
                  id="img3MyFamilySection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  className="absolute-image front"
                  src={load(`./resources/images/caregiver_p1_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textMyFamilySection2Title"
                  className="second-feature-title"
                >
                  {t("Accompany your children on their growth journey")}
                </div>
                <div
                  id="textMyFamilySection2Content"
                  className="second-feature-text"
                >
                  {t("Keep track of your children's growth and development")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1MyFamilySection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  className="absolute-image front"
                  src={load(`./resources/images/caregiver_p2_2.png`)}
                  alt=" "
                />
                <img
                  id="img2MyFamilySection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/${locale}/caregiver_p2_1.png`)}
                  alt=" "
                />
                <img
                  id="img3MyFamilySection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  className="absolute-image front"
                  src={load(`./resources/images/caregiver_p2_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section last-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textMyFamilySection3Title"
                  className="second-feature-title"
                >
                  {t("A proper management of appointment records")}
                </div>
                <div
                  id="textMyFamilySection3Content"
                  className="second-feature-text"
                >
                  {t(
                    "Helps you to keep track of all appointments records of your family"
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1MyFamilySection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/${locale}/caregiver_p3_1.png`)}
                  alt=" "
                />
                <img
                  id="img2MyFamilySection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  className="absolute-image front"
                  src={load(`./resources/images/caregiver_p3_2.png`)}
                  alt=" "
                />
                <img
                  id="img3MyFamilySection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-200"
                  className="absolute-image front"
                  src={load(`./resources/images/caregiver_p3_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-down"
          data-aos-offset="-200"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoMyFamily"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/votVVDrrZUw"
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>

        {/* <div className="container-fluid caregiver-tips" style={{display: "none"}}> */}
        <div className="container-fluid caregiver-tips">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-right"
                className="col-md-9 col-sm-12 col-sm-push-3"
              >
                <div
                  id="textMyFamilySection4Title"
                  className="second-usage-title"
                >
                  {t("How can I add a family member?")}
                </div>
                <div
                  id="textMyFamilySection4Content"
                  className="second-usage-text"
                >
                  {t(
                    "You may find relevant details on the above tutorial or on the My Family Members tab"
                  )}
                </div>
              </div>
              <div
                data-aos="fade-up"
                className="col-md-3 col-sm-12 col-sm-pull-9"
              >
                <img
                  id="img1MyFamilySection4"
                  src={load(`./resources/images/${locale}/caregiver_tip_1.png`)}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(CaregiverPage);
