import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class PrivacyPage extends Component {
  render() {
    const { locale, t } = this.props;

    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="static_page_header static_page_header_privacy">
          <div
            className="static_page_header_bg"
            style={{
              background: `url(${load(
                "./resources/images/privacy/privacy-header-photo.png"
              )}) no-repeat 60%`,
            }}
          ></div>
          <div className="container">
            <div className="row vertical-align">
              <div className="col-sm-6 privacy-header-text-col">
                <p
                  id="textPrivacyTopBannerTitle"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="400"
                  className="static_page_top_title"
                >
                  {t("Privacy Protection & Security")}
                  <span>
                    {t(
                      "We care about the protection and security of your data in eHealth App"
                    )}
                  </span>
                </p>
              </div>
              <div className="col-sm-6 static-page-header-left">
                <img
                  className="mobile-show"
                  src={load(
                    `./resources/images/privacy/privacy-header-photo.png`
                  )}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid second-feature-section privacy-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="" className="col-sm-12 align-self-center">
                <div
                  className="privacy-title"
                  data-aos="fade-down"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                >
                  <img
                    src={load(`./resources/images/privacy/mobile-icon-1.png`)}
                    className="privacy-title-icon"
                    alt=""
                  />
                  {t("Keeping your mobile device safe")}
                </div>
                <div
                  className="privacy-text"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                >
                  {t(
                    "Find out how you can secure your data on your mobile device"
                  )}
                </div>
                <div className="col-sm-2"></div>
                <div className="privacy-photo col-sm-8 align-self-center offset-sm-3">
                  <img
                    src={load(
                      `./resources/images/privacy/privacy-photo-empty.png`
                    )}
                    alt=""
                  />
                  <img
                    id="img1AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    className="absolute-image back"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-1.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img2AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    className="absolute-image middle desktop-show"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-2.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img2AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    className="absolute-image middle mobile-show"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-2-mobile.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img3AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    data-aos-delay="200"
                    className="absolute-image front"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-3.png`
                    )}
                    alt=" "
                  />
                  <p
                    className="privacy-case-content"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-350"
                    data-aos-delay="200"
                  >
                    {t("Keeping your mobile device safe - case content")}
                    <b>
                      {t("Keeping your mobile device safe - case highlight")}
                    </b>
                  </p>
                </div>
                <div className="col-sm-2"></div>
                <div className="row col-sm-12 privacy-reminder">
                  <div
                    className="col-xs-2 col-sm-3 privacy-reminder-img"
                    data-aos="fade-right"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-350"
                  >
                    <img
                      src={load(
                        `./resources/images/privacy/privacy-reminder-1.png`
                      )}
                      alt=""
                    />
                  </div>
                  <div
                    className="col-xs-10 col- col-sm-7 reminder-speak-bubble"
                    data-aos="fade-left"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-350"
                  >
                    <span>{t("privacy reminder bubble title")}</span>
                    <b>
                      {t("Perform regular backup, restore your lost data!")}
                    </b>
                  </div>
                  <div className="row clear vertical-align privacy-tip-row">
                    <div
                      className="col-xs-4 col-sm-2 privacy-tip-img"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <img
                        src={load(
                          `./resources/images/privacy/keep-safe-tip01.png`
                        )}
                        alt=""
                      />
                    </div>

                    <div
                      className="col-xs-8 col-sm-4 privacy-tip-content"
                      data-aos="fade-left"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <span>
                        {t("Install the latest updates of eHealth App")}
                      </span>
                    </div>
                    <div className="clear"></div>

                    <div
                      className="col-xs-4 col-sm-2 privacy-tip-img"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                      data-aos-delay="200"
                    >
                      <img
                        src={load(
                          `./resources/images/privacy/keep-safe-tip02.png`
                        )}
                        alt=""
                      />
                    </div>

                    <div
                      className="col-xs-8 col-sm-4 privacy-tip-content"
                      data-aos="fade-left"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                      data-aos-delay="200"
                    >
                      <span>{t("Regular backup of your data")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section privacy-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="" className="col-sm-12 align-self-center">
                <div
                  className="privacy-title"
                  data-aos="fade-down"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                >
                  <img
                    src={load(`./resources/images/privacy/mobile-icon-2.png`)}
                    className="privacy-title-icon"
                    alt=""
                  />
                  {t("Log in or log off properly")}
                </div>
                <div
                  className="privacy-text"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                >
                  {t("Avoid any unauthorized persons access your accounts")}
                </div>
                <div className="col-sm-2"></div>
                <div className="privacy-photo col-sm-8 align-self-center offset-sm-3">
                  <img
                    src={load(
                      `./resources/images/privacy/privacy-photo-empty.png`
                    )}
                    alt=""
                  />
                  <img
                    id="img1AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    className="absolute-image back"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-1.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img2AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    className="absolute-image middle desktop-show"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-2.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img2AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    className="absolute-image middle mobile-show"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-2-mobile.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img3AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    data-aos-delay="200"
                    className="absolute-image front"
                    src={load(
                      `./resources/images/privacy/privacy-photo2-3.png`
                    )}
                    alt=" "
                  />
                  <p
                    className="privacy-case-content"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-350"
                    data-aos-delay="200"
                  >
                    {t("Log in or log off properly - case content")}
                    <b>{t("Log in or log off properly - case highlight")}</b>
                  </p>
                </div>
                <div className="col-sm-2"></div>
                <div className="row col-sm-12 privacy-reminder">
                  <div
                    className="col-xs-2 col-sm-3 privacy-reminder-img"
                    data-aos="fade-right"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-350"
                  >
                    <img
                      src={load(
                        `./resources/images/privacy/privacy-reminder-2.png`
                      )}
                      alt=""
                    />
                  </div>
                  <div
                    className="col-xs-10 col- col-sm-7 reminder-speak-bubble"
                    data-aos="fade-left"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-350"
                  >
                    <span>{t("privacy reminder bubble title")}</span>
                    <b>
                      {t(
                        "Whenever you have logged in 醫健通eHealth App, tap “Logout” afterwards"
                      )}
                    </b>
                  </div>

                  <div className="row clear vertical-align privacy-tip-row">
                    <div
                      className="col-xs-4 col-sm-2 privacy-tip-img"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <img
                        src={load(
                          `./resources/images/privacy/keep-safe-tip03.png`
                        )}
                        alt=""
                      />
                    </div>

                    <div
                      className="col-xs-8 col-sm-4 privacy-tip-content"
                      data-aos="fade-left"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <span>{t("Stay vigilant while log-in")}</span>
                    </div>
                    <div className="clear"></div>

                    <div
                      className="col-xs-4 col-sm-2 privacy-tip-img"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                      data-aos-delay="200"
                    >
                      <img
                        src={load(
                          `./resources/images/privacy/keep-safe-tip04.png`
                        )}
                        alt=""
                      />
                    </div>

                    <div
                      className="col-xs-8 col-sm-4 privacy-tip-content"
                      data-aos="fade-left"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                      data-aos-delay="200"
                    >
                      <span>
                        {t("Do not store anyone else biometric credentials")}
                      </span>
                    </div>
                  </div>

                  <div className="row clear vertical-align privacy-tip-row second-privacy-tip-row">
                    <div className="col-xs-0 col-sm-3 privacy-tip-blank"></div>
                    <div
                      className="col-xs-4 col-sm-2 privacy-tip-img"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <img
                        src={load(
                          `./resources/images/privacy/keep-safe-tip05.png`
                        )}
                        alt=""
                      />
                    </div>

                    <div
                      className="col-xs-8 col-sm-4 privacy-tip-content"
                      data-aos="fade-left"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <span>
                        {t("Remember to tap “Logout” on the App after using")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section privacy-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="" className="col-sm-12 align-self-center">
                <div
                  className="privacy-title"
                  data-aos="fade-down"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                >
                  <img
                    src={load(`./resources/images/privacy/mobile-icon-3.png`)}
                    className="privacy-title-icon"
                    alt=""
                  />
                  {t("Beware of privacy leakage")}
                </div>
                <div
                  className="privacy-text"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                >
                  {t("Learn about how to protect your accounts")}
                </div>
                <div className="col-sm-2"></div>
                <div className="privacy-photo col-sm-8 align-self-center offset-sm-3">
                  <img
                    src={load(
                      `./resources/images/privacy/privacy-photo-empty.png`
                    )}
                    alt=""
                  />
                  <img
                    id="img1AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    className="absolute-image back"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-1.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img2AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    className="absolute-image middle desktop-show"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-2.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img2AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    className="absolute-image middle mobile-show"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-2-mobile.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img3AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    data-aos-delay="200"
                    className="absolute-image front"
                    src={load(
                      `./resources/images/privacy/privacy-photo3-3.png`
                    )}
                    alt=" "
                  />
                  <p
                    className="privacy-case-content"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-350"
                    data-aos-delay="200"
                  >
                    {t("Beware of privacy leakage - case content")}
                    <b>{t("Beware of privacy leakage - case highlight")}</b>
                  </p>
                </div>
                <div className="col-sm-2"></div>
                <div className="row col-sm-12 privacy-reminder">
                  <div
                    className="col-xs-2 col-sm-3 privacy-reminder-img"
                    data-aos="fade-right"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-350"
                  >
                    <img
                      src={load(
                        `./resources/images/privacy/privacy-reminder-2.png`
                      )}
                      alt=""
                    />
                  </div>
                  <div
                    className="col-xs-10 col- col-sm-7 reminder-speak-bubble"
                    data-aos="fade-left"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-350"
                  >
                    <span>{t("privacy reminder bubble title")}</span>
                    <b>
                      {t("Secure your login credentials to avoid any misuse")}
                    </b>
                  </div>

                  <div className="row clear vertical-align privacy-tip-row">
                    <div
                      className="col-xs-4 col-sm-2 privacy-tip-img"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <img
                        src={load(
                          `./resources/images/${locale}/privacy/keep-safe-tip06.png`
                        )}
                        alt=""
                      />
                    </div>

                    <div
                      className="col-xs-8 col-sm-4 privacy-tip-content"
                      data-aos="fade-left"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <span>{t("Keep eyes on the last login time")}</span>
                    </div>
                    <div className="clear"></div>

                    <div
                      className="col-xs-4 col-sm-2 privacy-tip-img"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                      data-aos-delay="200"
                    >
                      <img
                        src={load(
                          `./resources/images/privacy/keep-safe-tip07.png`
                        )}
                        alt=""
                      />
                    </div>

                    <div
                      className="col-xs-8 col-sm-4 privacy-tip-content"
                      data-aos="fade-left"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                      data-aos-delay="200"
                    >
                      <span>
                        {t("Report any suspicious activity in your account")}
                      </span>
                    </div>
                  </div>

                  <div className="row clear vertical-align privacy-tip-row second-privacy-tip-row">
                    <div className="col-xs-0 col-sm-3 privacy-tip-blank"></div>
                    <div
                      className="col-xs-4 col-sm-2 privacy-tip-img"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <img
                        src={load(
                          `./resources/images/privacy/keep-safe-tip08.png`
                        )}
                        alt=""
                      />
                    </div>

                    <div
                      className="col-xs-8 col-sm-4 privacy-tip-content"
                      data-aos="fade-left"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <span>
                        {t("Do not share your username & password to others")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section privacy-section last-privacy-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="" className="col-sm-12 align-self-center">
                <div
                  className="privacy-title"
                  data-aos="fade-down"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                >
                  <img
                    src={load(`./resources/images/privacy/mobile-icon-4.png`)}
                    className="privacy-title-icon"
                    alt=""
                  />
                  {t("Beware of phishing attack")}
                </div>
                <div
                  className="privacy-text"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                >
                  {t(
                    "Beware of hackers sending text messages, email, voice calls, QR codes, etc. as bait to deceive you"
                  )}
                </div>
                <div className="col-sm-2"></div>
                <div className="privacy-photo col-sm-8 align-self-center offset-sm-3">
                  <img
                    src={load(
                      `./resources/images/privacy/privacy-photo-empty.png`
                    )}
                    alt=""
                  />
                  <img
                    id="img1AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    className="absolute-image back"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-4.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img2AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    className="absolute-image middle desktop-show"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-2-long.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img2AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    className="absolute-image middle mobile-show"
                    src={load(
                      `./resources/images/privacy/privacy-photo1-2-mobile.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img3AllergySection2"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-600"
                    data-aos-delay="200"
                    className="absolute-image front"
                    src={load(
                      `./resources/images/privacy/${locale}/privacy-photo1-4.png`
                    )}
                    alt=" "
                  />
                  <p
                    className="privacy-case-content"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-350"
                    data-aos-delay="200"
                  >
                    {t("Beware of phishing attack - case content")}
                    <br />
                    <br />
                    <b>{t("Beware of phishing attack - case highlight")}</b>
                  </p>
                </div>
                <div className="col-sm-2"></div>
                <div className="row col-sm-12 privacy-reminder">
                  <div
                    className="col-xs-2 col-sm-3 privacy-reminder-img"
                    data-aos="fade-right"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-350"
                  >
                    <img
                      src={load(
                        `./resources/images/privacy/privacy-reminder-4.png`
                      )}
                      alt=""
                    />
                  </div>
                  <div
                    className="col-xs-10 col- col-sm-7 reminder-speak-bubble"
                    data-aos="fade-left"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-350"
                  >
                    <span>{t("privacy reminder bubble title")}</span>
                    <b>
                      {t(
                        "Check the sender’s details carefully and do not click on the hyperlinks or attachments in suspicious emails or messages"
                      )}
                    </b>
                  </div>

                  <div className="row clear vertical-align privacy-tip-row">
                    <div
                      className="col-xs-4 col-sm-2 privacy-tip-img"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <img
                        src={load(
                          `./resources/images/privacy/phishing-attack-tip01.png`
                        )}
                        alt=""
                      />
                    </div>

                    <div
                      className="col-xs-8 col-sm-4 privacy-tip-content"
                      data-aos="fade-left"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <span>
                        {t("Do not log into websites that are not verified")}
                      </span>
                    </div>
                    <div className="clear"></div>

                    <div
                      className="col-xs-4 col-sm-2 privacy-tip-img"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                      data-aos-delay="200"
                    >
                      <img
                        src={load(
                          `./resources/images/privacy/phishing-attack-tip02.png`
                        )}
                        alt=""
                      />
                    </div>

                    <div
                      className="col-xs-8 col-sm-4 privacy-tip-content"
                      data-aos="fade-left"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                      data-aos-delay="200"
                    >
                      <span>
                        {t(
                          "Pay extra attention if the websites ask for personal or credit card details"
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="row clear vertical-align privacy-tip-row second-privacy-tip-row">
                    <div className="col-xs-0 col-sm-2 privacy-tip-blank"></div>
                    <div
                      className="col-xs-4 col-sm-2 privacy-tip-img"
                      data-aos="fade-right"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <img
                        src={load(
                          `./resources/images/privacy/phishing-attack-tip03.png`
                        )}
                        alt=""
                      />
                    </div>

                    <div
                      className="col-xs-8 col-sm-8 privacy-tip-content tip-long"
                      data-aos="fade-left"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-530"
                    >
                      <span>
                        {t(
                          "If you suspect that you have fallen prey to a scam, save relevant emails or messages and report the case to the police."
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(PrivacyPage);
