import React, { Component } from 'react';
import load from './../../../ImageLoder';
import '../../../resources/styles/bannerSlider.scss';

class Swiper extends Component{
    constructor(props){
        super(props);
        this.swiperContainerRef = React.createRef();
        this.initalSwiperItemRef = React.createRef();
        this.state = { 
            imageWidth: 0,
            currentSlide: 0,
            numberOfImage: 3,
            sliderInterval: null
        };
        this.setCurrentSlide.bind(this);
    }

    componentDidMount() {
        window.addEventListener('load', () =>  {
            this.setState({
                imageWidth: this.initalSwiperItemRef.current.clientWidth
              });
            this.swiperContainerRef.current.style.transform = `translateX(${this.initalSwiperItemRef.current.clientWidth}px)`;
        });
    }

    setCurrentSlide(index){
        this.setState({
            currentSlide: index
        });
        this.swiperContainerRef.current.style.transform = `translateX(${this.state.imageWidth - index * this.state.imageWidth}px)`;
    }

    nextSlide(){
        if(this.setState.currentSlide != this.state.numberOfImage - 1){
            this.setCurrentSlide(this.setState.currentSlide + 1)
        }
    }

    previousSlide(){
        if(this.setState.currentSlide != 0){
            this.setCurrentSlide(this.setState.currentSlide - 1)
        }
    }


    render() {
        const {t, locale, languageCodeMap} = this.props;

        return (
            <>
                <div lang={locale} className="swiper-container" ref={this.swiperContainerRef}>
                    <div className={`swiper-item ${this.state.currentSlide == 0 ? 'active' : 'inactive'}`} ref={this.initalSwiperItemRef} onClick={() => {this.setCurrentSlide(0)}}>
                        <img className="ehealth-website-image" src={load(`./resources/images/truck-slider-1.jpg`)} alt={t(" ")} />
                        {/* <img className="display-mobile ehealth-website-image" src={load(`./resources/images/truck-slider-1.jpg`)} alt={t("Brand new function of eHealth App is available now! Health Management helps you keep track of your blood pressure or blood sugar record with ease. Click here for more information.")} /> */}
                    </div>
                    <div className={`swiper-item ${this.state.currentSlide == 1 ? 'active' : 'inactive'}`} onClick={() => {this.setCurrentSlide(1)}}>
                        <img className="ehealth-website-image" src={load(`./resources/images/truck-slider-3.jpg`)} alt={t(" ")} />
                        {/* <img className="display-mobile ehealth-website-image" src={load(`./resources/images/truck-slider-3.jpg`)} alt={t("What do you think of e Health? We would love to hear from you!Please take the survey now!")} /> */}
                    </div>        
                    <div className={`swiper-item ${this.state.currentSlide == 2 ? 'active' : 'inactive'}`} onClick={() => {this.setCurrentSlide(2)}}>
                        <img className="ehealth-website-image" src={load(`./resources/images/truck-slider-2.jpg`)} alt={t(" ")} />
                        {/* <img className="display-mobile ehealth-website-image" src={load(`./resources/images/truck-slider-2.jpg`)} alt={t("What do you think of e Health? We would love to hear from you!Please take the survey now!")} /> */}
                    </div>         
                </div>
                <div className='swiper-nav-container'>
                    <span onClick={() => {this.setCurrentSlide(0)}} className={`swiper-nav-item ${this.state.currentSlide == 0 ? 'active' : 'inactive'}`}></span>
                    <span onClick={() => {this.setCurrentSlide(1)}} className={`swiper-nav-item ${this.state.currentSlide == 1 ? 'active' : 'inactive'}`}></span>
                    <span onClick={() => {this.setCurrentSlide(2)}} className={`swiper-nav-item ${this.state.currentSlide == 2 ? 'active' : 'inactive'}`}></span>
                </div>
            </>
        );
    }
}

export default Swiper;