import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class ClinicalAppointmentPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = [
      "appointment_slider_phone1.png",
      "appointment_slider_phone2.png",
      "appointment_slider_phone3.png",
    ];
    const sliderImageAlts = [
      "Screenshot of Appointments",
      "Screenshot of Appointments",
      "Screenshot of Appointments",
    ];
    const sliderImageIds = [
      "img1AppointmentSlider",
      "img2AppointmentSlider",
      "img3AppointmentSlider",
    ];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"clinical-appointment-bg.png"}
        sliderID="appointmentSlider"
        sliderTitle={t("Appointment Header")}
        sliderTitleID={"textAppointmentSliderTitle"}
        sliderText={t("Check out your appointment record effortlessly")}
        sliderTextID="textAppointmentliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );

    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textAppointmentSection1Title"
                  className="second-feature-title"
                >
                  {t("Every appointment at your control")}
                </div>
                <div
                  id="textAppointmentSection1Content"
                  className="second-feature-text"
                >
                  {t("Tap Upcoming or Past to see related appointment record")}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1AppointmentSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/${locale}/appointment_p1_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2AppointmentSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/appointment_p1_2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section ">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textAppointmentSection2Title"
                  className="second-feature-title"
                >
                  {t("Add your appointment with ease​")}
                </div>
                <div
                  id="textAppointmentSection2Content"
                  className="second-feature-text"
                >
                  {t("Manage all your medical appointments in one place​")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1AppointmentSection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image "
                  src={load(`./resources/images/appointment_p3_1.png`)}
                  alt=" "
                />
                <img
                  id="img2AppointmentSection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="150"
                  className="absolute-image front"
                  src={load(`./resources/images/appointment_p3_3.png`)}
                  alt=" "
                />
                <img
                  id="img3AppointmentSection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/appointment_p3_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section ">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textAppointmentSection1Title"
                  className="second-feature-title"
                >
                  {t("Share appointment details at your fingertips")}
                </div>
                <div
                  id="textAppointmentSection1Content"
                  className="second-feature-text"
                >
                  {t("Communicating with your family made easy")}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1AppointmentSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image "
                  src={load(
                    `./resources/images/${locale}/appointment_p2_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2AppointmentSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/appointment_p2_3.png`)}
                  alt=" "
                />
                <img
                  id="img3AppointmentSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/appointment_p2_2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section last-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textAppointmentSection2Title"
                  className="second-feature-title"
                >
                  {t("Integrating with various booking systems")}
                </div>
                <div
                  id="textAppointmentSection2Content"
                  className="second-feature-text"
                >
                  {t("Facilitate your appointment booking process")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1AppointmentSection4"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image "
                  src={load(
                    `./resources/images/${locale}/appointment_p4_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2AppointmentSection4"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="150"
                  className="absolute-image front"
                  src={load(`./resources/images/appointment_p4_2.png`)}
                  alt=" "
                />
                <img
                  id="img3AppointmentSection4"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/appointment_p4_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-down"
          data-aos-offset="-300"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoAppointment"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/dDPx0s2OvGc"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(ClinicalAppointmentPage);
